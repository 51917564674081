import * as React from "react";
import { Container, Row } from 'react-bootstrap';

const WorkDetailImageGallery = ({ children }) => {
  return (
    <Container fluid className="workGallery">
      <Row>
        {children}
      </Row>
    </Container>
  );
};

export default WorkDetailImageGallery;
