import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Seo from "../../components/seo";
import Layout from "../../components/layout";
import WorkDetailSummary from "./work-detail-summary";
import WorkDetailRelatedLinks from "./work-detail-related-links";
import WorkDetailRelatedWorks from "./work-detail-related-works";

const WorkDetail = ({ location, summary, heroContent, relatedLinks, ogpImage, children }) => {
  const { worksJson } = useStaticQuery(
    graphql`
      query {
        worksJson: allWorksJson {
          edges {
            node {
              category
              client
              slug
              title
              year
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 960)
                }
              }
              imagePosition
              url
              id
            }
          }
        }
      }
    `
  );

  const slug = location.pathname.split('/')[2];
  const workInfo = worksJson.edges.find((edge) => edge.node.slug === slug).node;
  const category = workInfo.category[0];
  const relatedWorks = worksJson.edges.filter((edge) => edge.node.category.includes(category) && edge.node.slug !== workInfo.slug);
  const title = workInfo.title.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'');

  return (
    <React.Fragment>
      <Seo title={title} pageClass="workDetailPage" ogpImage={ogpImage} />
      <Layout>
        {heroContent()}
        <WorkDetailSummary workInfo={workInfo} summary={summary} />
        {children}
        <WorkDetailRelatedLinks relatedLinks={relatedLinks} />
        <WorkDetailRelatedWorks relatedWorks={relatedWorks} />
      </Layout>
    </React.Fragment>
  );
};

export default WorkDetail;

WorkDetail.defaultProps = {
  relatedLinks: [],
};

WorkDetail.propTypes = {
  relatedLinks: PropTypes.array,
};
