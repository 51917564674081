import * as React from "react";
import PropTypes from "prop-types";
import { Container } from 'react-bootstrap';

const WorkDetailHero = ({ className, children }) => {
  const cls = (className === '') ? 'workHeaderHero p-0' : `workHeaderHero p-0 ${className}`
  return (
    <Container fluid className={cls}>
      <div className="workHeroOuter">
        {children}
      </div>
    </Container>
  );
};

export default WorkDetailHero;

WorkDetailHero.defaultProps = {
  className: '',
};

WorkDetailHero.propTypes = {
  className: PropTypes.string,
};
