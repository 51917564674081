import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="BS_bSJ6CMfQ" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        海水が入り込むマングローブ林の奥でコツコツと泥を積み塚をつくる「オキナワアナジャコ」と言う不思議な生き物がいる。高さが1mくらいの塚は雨によって塩分が抜け、陸上の草木がはえて陸地化していく。言わばこの小さな生き物によつて島の陸地が増えできたことになる。西表の人々はここを田んぼとして利用してきた。この「オキナワアナジャコ」を手がかりに、西表島の水とマングローブとさまざまな生き物たちを記録したドキュメンタリーである。<br />
        「奄美大島、徳之島、沖縄島北部及び西表島」が2021年7月、世界自然遺産に登録された。理由はIUCNのレッドリストに載る絶滅危惧種の生息地であり、島の成り立ちを反映した独自の生物進化を背景にして、生物多様性保全上重要な地域であることが評価されたとのことである。<br />
        私たちはちょうど20年前、西表島で5月から約100日間泊まり込みで撮影を敢行した。当時は、現在のように大型リゾートホテルもなく、田んぼでは水牛が農夫とともに活躍していた。<br />
        ナレーション：田中好子<br />
        (文部科学省選定)<br />
        Amazonにて好評発売中！
      </p>
    );
  };

  const summaryData = {
    scopes: ['All'],
    awards: [],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(0, 3)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/iriomote"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/iriomote.*"}) {
      publicURL
    }
  }
`;
