import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const WorkDetailRelatedLinks = ({ relatedLinks }) => {
  if (relatedLinks.length === 0) {
    return null;
  }

  return (
    <Container fluid className="workLink">
      <Row>
        <Col>
          <h3 className="titleTopBorder">Related link</h3>
          <ul className="p-0">
            {
              relatedLinks.map(({text, href}) => (
                <li>
                  <a href={href} target="_blank" rel="noreferrer">
                    <p>{text}</p>
                    <span>↗</span>
                  </a>
                </li>
              ))
            }
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default WorkDetailRelatedLinks;
