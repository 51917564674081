import * as React from "react";
import PropTypes from "prop-types";
import { Col } from 'react-bootstrap';
import { GatsbyImage } from "gatsby-plugin-image";

const WorkDetailImages = ({ images, column }) => {
  if (images.length === 0) {
    return null;
  }
  const col = Math.min(Math.max(1, parseInt(column)), 4);
  const lg = 12 / col;

  return (
    <React.Fragment>
      {
        images.map((image) => (
          <Col lg={lg} key={image.node.id}>
            <GatsbyImage image={image.node.childImageSharp.gatsbyImageData} layout="constrained" alt="" />
          </Col>
        ))
      }
    </React.Fragment>
  );
};

export default WorkDetailImages;

WorkDetailImages.defaultProps = {
  images: [],
  column: 1,
};

WorkDetailImages.propTypes = {
  images: PropTypes.array,
  column: PropTypes.number,
};
